<script lang="ts">
  import type { LangType } from '@customTypes/newscraft'
  import { useTranslations } from '@i18n/utils'
  import Icon from '@iconify/svelte'
  import { signUpNewsletter } from '@utils/fetchUser'
  import { Helper, Spinner } from 'flowbite-svelte'

  export let lang: LangType

  const t = useTranslations(lang)

  let emailErrorMessage = ''
  let emailInput = ''
  let loading = false
  let isFormSubmitted = false

  async function submit() {
    try {
      loading = true
      const res = await signUpNewsletter(emailInput)

      if (res?.statusText === 'OK') {
        loading = false
        isFormSubmitted = true
      }
    } catch (error) {
      console.log(error)
    }
  }

  function checkEmailValid(e: Event) {
    const value = (e.target as HTMLInputElement).value
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    if (!value) {
      emailErrorMessage = ''
      emailInput = ''
    } else if (!emailRegex.test(value)) {
      emailErrorMessage = t('newsletterSignUp.emailError')
      emailInput = ''
    } else {
      emailErrorMessage = ''
      emailInput = value
    }
  }
</script>

<div class="my-10 flex max-w-full flex-col rounded-lg border border-slate-100 p-6 shadow">
  <div class="flex-none">
    <div class="flex gap-x-1 text-slate-900">
      <Icon
        icon="mdi:email-newsletter"
        class="size-8"
      />
      <h2 class="text-center text-xl font-semibold">
        {t('newsletter')}
      </h2>
    </div>
    <p class="my-3 text-sm text-slate-900">
      {t('newsletterSignUp.get')}
    </p>
  </div>
  <div class="flex grow items-center justify-between">
    {#if loading}
      <div class="mx-auto flex h-40 items-center justify-center">
        <Spinner color="gray" />
      </div>
    {:else if isFormSubmitted}
      <div
        class="flex h-full w-full flex-col items-center justify-evenly space-y-3 rounded-lg bg-green-100 p-4 text-center text-sm font-semibold text-emerald-700"
      >
        <Icon
          icon="mdi:check-circle-outline"
          class="size-10 text-green-500"
        />
        <p class="leading-relaxed">{t('newsletterSignUp.success')}</p>
        <p class="leading-related">{t('newsletterSignUp.whenToSend')}</p>
      </div>
    {:else}
      <form
        on:submit|preventDefault={submit}
        class="w-full"
      >
        <label
          for="email"
          class={`mb-2 block text-sm font-medium ${
            emailInput ? 'text-green-500' : emailErrorMessage ? 'text-red-700 ' : 'text-slate-900'
          }`}>{t('input.emailLabel')}</label
        >
        <div class="flex gap-x-2">
          <input
            type="email"
            id="email"
            class={`block w-full basis-3/4 rounded-lg border p-2.5 text-sm ${
              emailInput
                ? 'border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500'
                : emailErrorMessage
                  ? 'border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500'
                  : 'border-slate-300 bg-slate-50 text-slate-900 focus:border-brandBlue focus:ring-brandBlue'
            }`}
            required
            on:change={e => checkEmailValid(e)}
          />
          <button
            type="submit"
            class="h-max w-full basis-1/4 whitespace-nowrap rounded-lg bg-brandOrange px-5 py-2.5 text-center text-sm font-medium text-white duration-300 hover:bg-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-500"
          >
            {t('signUp')}
          </button>
        </div>
        <Helper class="mt-2 h-4 text-sm text-red-600">
          {emailErrorMessage}
        </Helper>
      </form>
    {/if}
  </div>
</div>
